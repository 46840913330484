.BannerTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #171616;
    margin-top: 100px;
    padding: 10%;
}
.Title {
    color: #3EE04E;
}
.Text {
    color: #fff;
    text-align: center;
}