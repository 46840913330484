.Container {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 100px;
}
.Title {
    color: #3EE04E;
    font-weight: bold;
    margin-bottom: 50px;
}
.ImgContainer {
    width: 200px;
}
.img {
    height: 100px;
    width: 150px;
}
.text {
    margin-left: 20px;
    display: flex;
    justify-content: left;
    flex-direction: column;
}
.serviceItem {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.productTitle {
    font-weight: bold;
}