.HomePageContainer {
    margin-top: -200px;
}
.Banner {
    display: flex;
    justify-content: center;
    align-items: center;
}
.BannerText {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 100%;
}