.Container {
    margin-top: 200px;
}
.OfferImg {
    justify-content: center;
}
.Photo {
    width: 100%;
}
.title {
    margin-top: 50px;
    text-align: center;
    color: rgb(62, 224, 78);
}
.CardComponent {
    height: auto;
    align-self: center;
}
.CardCol {
    margin-top: 10px;
}