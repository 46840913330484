.TextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.Text {
    text-align: center;
}
.Title {
    text-align: center;
}