.Container {
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 100px;
    text-align: center;
    background-color: black;
}
.Title {
    color: rgb(62, 224, 78);
}
.LogoText {
    color: #fff;
}
.LogoCol {
    margin-top: 100px;
}