.Container {
    margin-top: -200px;
}
.BannerText {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: #fff;
    width: 100%;
    height: 100%;
  }
  .title {
      margin-left: 10%;
      font-weight: bold;
  }